<template>
  <div class="d-flex flex-stack" style="padding-bottom:10px;">
    <div class="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
    </div>
    <div class="col-md-6" align="right">
      <div class="me-4">
        <el-input
            v-model="search.searchValue"
            placeholder="Type to search"
            class="col-md-10"
            style="padding-right:10px;"/>
        <button
            @click="openCreateStaff"
            class="btn btn-sm btn-primary"
            id="staff_create_button"
        >
        Create
        </button>
      </div>
    </div>
  </div>
    <div class="card mb-5 mb-xl-10" id="agent_list">
        <el-table :data="pagedTableDate" style="width: 100%;text-align:center;" @row-click="selectStaff">
            <el-table-column prop="staff_id" label="Id"></el-table-column>
            <el-table-column prop="name" label="Name">
                <template v-slot="scope">          
                    <span>{{scope.row.first_name}} {{scope.row.last_name}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="type" label="Type"></el-table-column>
            <el-table-column prop="position" label="Position"></el-table-column>            
            <el-table-column prop="phone" label="Phone"></el-table-column>
            <el-table-column prop="email" label="Email"></el-table-column>
            <el-table-column 
                prop="is_active" 
                label="Status"
                :filters="[
                    { text: 'Active', value: true },
                    { text: 'Inactive', value: false },
                ]"
                :filter-method="filterActive">
                <template v-slot="scope">          
                <span v-if="scope.row.is_active" class="badge-light-success badge fs-8 fw-bolder my-2">Active</span>
                <span v-else class="badge-light-danger badge fs-8 fw-bolder my-2">Inactive</span>
                </template>
            </el-table-column>
        </el-table>
        <div align="right">
            <el-pagination 
              layout="sizes, prev, pager, next" 
              v-model:page-size="pageSize" 
              :page-sizes="pageSizes"
              :small="small"
              :disabled="disabled"
              :background="background"
              :total="getFilteredStaffList().length" 
              @size-change="handleSizeChange"
              @current-change="setPage">
            </el-pagination>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, computed, reactive, onBeforeMount, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import router from "@/router";

export default defineComponent({
  name: "staff-list",
  components: {
  },
  setup() {
    const staffs = reactive<any>([]);

    const getStaffs = async () => {
        await new Promise((resolve, reject) => {   
          ApiService.setHeader();         
            ApiService.get("staff")
                .then(({ data }) => {
                    Object.assign(staffs,data.data);
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(new Error ('getStaffs error => '+response.data.errors));
                });
        });
    }

    const pageSize = ref(20);
    let page = reactive({pageValue : 1});

    const pagedTableDate = computed(()=>{
      if(staffs){
          return getFilteredStaffList().slice(pageSize.value * page.pageValue - pageSize.value, pageSize.value * page.pageValue)
      }
      else{
          return [];
      }
     })

    const getFilteredStaffList = () =>{
      return staffs.filter(data => !search 
                                  || (data.first_name+' '+data.last_name)?.toLowerCase().includes(search.searchValue.toLowerCase())
                                  || data.phone?.toLowerCase().includes(search.searchValue.toLowerCase())
                                  || data.email?.toLowerCase().includes(search.searchValue.toLowerCase())
                                  || data.type?.toLowerCase().includes(search.searchValue.toLowerCase()));
    }

    const pageSizes = computed(()=>{
      let sizes = [20];
      let curr_length = getFilteredStaffList().length;

      if(curr_length > 20){
        sizes.push(50);
        if(curr_length >50){
          let i = 0;
          while(i<curr_length){
            i+=100;
            sizes.push(i);
          }
        }
      }

      return sizes;
    })

    const search = reactive({searchValue : ''});

    const setPage = (val) => {
      page.pageValue = val;
    }
    
    const handleSizeChange = (val: number) => {
      pageSize.value = val; 
    }

    const filterActive = (value, row) => {
      return row.is_active === value
    }

    const selectStaff= (row) =>{
      router.push({ name: "staff-detail", params: { id: row.id } });
    }

    const openCreateStaff= () =>{
      router.push({ name: "staff-detail", params: { id: 0 } });
    }

    onBeforeMount(async() => {
      await getStaffs();
    })

    onMounted(() => {
      setCurrentPageBreadcrumbs("Faculty/Staff", ["Management"]);
    });

    return {
      handleSizeChange,
      pagedTableDate,
      pageSize,
      search,
      filterActive,
      selectStaff,
      staffs,
      pageSizes,
      getFilteredStaffList,
      openCreateStaff,
      setPage
    };
  },
});
</script>

<style>
.el-table th>.cell.highlight{
    background-color:transparent;
    padding: 0px;
}

.cell {
    text-align:center;
}
</style>
